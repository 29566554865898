/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import makeStyles from '@mui/styles/makeStyles'

const courseTileHeightWithoutPreviewArea = '157px'
export const cardMediaQueries = theme => ({
    width: 340,
    [theme.breakpoints.up('mobileSmall')]: {
        height: `calc((80vw - 16px) / 2 + ${courseTileHeightWithoutPreviewArea})`
    },
    [theme.breakpoints.up('mobileMedium')]: {
        height: `calc((80vw - 16px) / 2 + ${courseTileHeightWithoutPreviewArea})`
    },
    [theme.breakpoints.up('mobileLarge')]: {
        height: `calc((80vw - 16px) / 2 + ${courseTileHeightWithoutPreviewArea})`
    },
    [theme.breakpoints.up('tabletSmall')]: {
        height: `calc((44vw - 32px) / 2 + ${courseTileHeightWithoutPreviewArea})`
    },
    [theme.breakpoints.up('tabletLarge')]: {
        height: `calc((30vw - 32px) / 2 + ${courseTileHeightWithoutPreviewArea})`
    },
    [theme.breakpoints.up('desktopMedium')]: {
        height: `calc((100vw - 192px) / 8 + ${courseTileHeightWithoutPreviewArea})`
    },
    [theme.breakpoints.up('desktopLarge')]: {
        height: `calc((100vw - 224px) / 10 + ${courseTileHeightWithoutPreviewArea})`
    }
})

export const useMediaQueries = makeStyles(theme => ({
    card: { ...cardMediaQueries(theme) }
}))
